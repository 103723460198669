import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {GalerieComponent} from "./galerie/galerie.component";
import {LiederlisteComponent} from "./liederliste/liederliste.component";
import {UeberUnsComponent} from "./ueber-uns/ueber-uns.component";
import {KontaktComponent} from "./kontakt/kontakt.component";
import {ImpressumComponent} from "./impressum/impressum.component";
import {VideosComponent} from "./videos/videos.component";
import {TermineComponent} from "./termine/termine.component";

const routes: Routes = [
    {path: '', component: HomeComponent, pathMatch: 'full'},
    {path: 'galerie', component: GalerieComponent, pathMatch: 'full'},
    {path: 'videos', component: VideosComponent, pathMatch: 'full'},
    {path: 'lieder', component: LiederlisteComponent, pathMatch: 'full'},
    {path: 'ueber-uns', component: UeberUnsComponent, pathMatch: 'full'},
    {path: 'kontakt', component: KontaktComponent, pathMatch: 'full'},
    {path: 'impressum', component: ImpressumComponent, pathMatch: 'full'},
    {path: 'termine', component: TermineComponent, pathMatch: 'full'}

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
