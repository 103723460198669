import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class VideoService {

    constructor() {
    }

    getVideos(): string[] {
        return [
            "VID-20210613-WA0005.mp4", "VID-20210613-WA0006.mp4", "VID-20210613-WA0007.mp4", "VID-20210613-WA0008.mp4",
            "VID-20210709-WA0001.mp4", "VID-20210711-WA0001.mp4", "VID-20210711-WA0002.mp4", "Blue Bayou (1).mp4",
            "Butterfly.mp4", "EVA Im Wagen vor mir.mp4", "EVA Wär ich ein Buch.mp4", "Lady Sunshine und Mr Moon.mp4",
            "Quando Quando.mp4", "Griechischer Wein.mp4", "Gute Nacht Freunde.mp4", "Blue Bayou.mp4",
            "Que sera sera.mp4", "Wunder geschehen.mp4", "VID-20211205-WA0003.mp4", "VID-20211205-WA0004.mp4",
            "Ich liebe das Leben.mp4", "Liebeskummer.mp4", "Rose garden.mp4", "Something stupid.mp4", "Stumblin in.mp4", "Gute Nacht Freunde 2.mp4", "VID-20221204-WA0012.mp4", "VID-20221204-WA0008.mp4", "VID-20221204-WA0006", "VID-20221204-WA0005.mp4", "VID-20230306-WA0000.mp4", "VID-20230306-WA0001.mp4", "VID-20230306-WA0002.mp4", "VID-20230306-WA0006.mp4", "VID-20230515-WA0000.mp4", "VID-20230515-WA0001.mp4", "VID-20230529-WA0000.mp4", "Liebeskummer2.mp4", "Dream.mp4", "Fernando.mp4", "words.mp4", "fly.mp4", "Ich.mp4", "Wonderful World MINI.mp4", "Wunder geschehen MINI.mp4", "Wenn aus Liebe Leben wird MINI.mp4", "Ins Wasser fällt ein Stein MINI.mp4", "Love me tender.mp4", "Irgendwo auf der Welt.mp4", "Sailing.mp4"];
    }
}
