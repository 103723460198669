import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import * as $ from 'jquery';
import {NavigationEnd, Router} from "@angular/router";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

    @ViewChild('navbar', {read: ElementRef, static: false}) navbar: ElementRef;
    @ViewChild('mainPicture', {read: ElementRef, static: false}) picture: ElementRef;

    navHeight: number;
    picHeight: number;
    currentPage: string = "";

    constructor(private router: Router) {
        router.events.subscribe(e => {
            if (e instanceof NavigationEnd) {
                this.currentPage = e.url;
            }
        })
    }

    public ngOnInit() {}
}
