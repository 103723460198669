import {AfterViewInit, Component} from '@angular/core';
import {ImageService} from "../image.service";

@Component({
    selector: 'galerie',
    templateUrl: './galerie.component.html',
    styleUrls: ['./galerie.component.scss']
})
export class GalerieComponent implements AfterViewInit {

    images: string[] = [];
    active: string = "";
    activeIndex: number;
    thumbnailImages: string[] = [];
    showCarousel: boolean = true;

    constructor(private imageService: ImageService) {
        this.images = imageService.getImages();
        this.activeIndex = 0;
        this.active = this.images[this.activeIndex];
        this.thumbnailImages = this.setThumbnailImages(this.activeIndex);
    }

    ngAfterViewInit(): void {
        let img = document.querySelector('img');
        if (img.complete) {
            this.loaded();
        } else {
            img.addEventListener('load', this.loaded);
        }
    }

    setThumbnailImages(index): string[] {
        return [this.getImage(index - 2), this.getImage(index - 1), this.getImage(index), this.getImage(index + 1), this.getImage(index + 2)];
    }

    getImage(index): string {
        if (index < 0)
            return this.images[this.images.length + index];
        else if (index > this.images.length - 1)
            return this.images[index - this.images.length];
        else
            return this.images[index];
    }

    setIndex(index) {
        if (index < 0)
            return this.images.length + index;
        else if (index > this.images.length - 1)
            return index - this.images.length;
        else
            return index;
    }

    setActiveImg(img): void {
        this.active = img;
        this.activeIndex = this.images.indexOf(img);
        this.thumbnailImages = this.setThumbnailImages(this.activeIndex);
    }

    slide(change): void {
        this.activeIndex = this.setIndex(this.activeIndex += change);
        this.active = this.getImage(this.activeIndex);
        this.thumbnailImages = this.setThumbnailImages(this.activeIndex);
    }

    loaded() {
        let picHeight = document.getElementById('picture').offsetHeight - 10 + "px";
        let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
        let footerHeight = document.getElementById('footer').offsetHeight;
        let relative: HTMLElement = document.getElementById('relative')
        relative.style.height = vh - footerHeight - 52 + 'px';

        let container: HTMLElement = document.getElementById('container');
        container.style.top = picHeight;
    }

    /**
     * Show an image big
     * @param id ID of the html img tag
     */
    openImage = function (id) {
        let modal = document.getElementById('modal');
        let img: HTMLImageElement = document.getElementById(id) as HTMLImageElement;
        let modalImg: HTMLImageElement = document.getElementById('modalImg') as HTMLImageElement;

        modal.style.display = "block";
        modalImg.src = img.src;
    }

    // Close the big image
    closeImage = function () {
        let modal = document.getElementById('modal');
        modal.style.display = "none";
    }

    imageLeft = function () {
        let img: HTMLImageElement = document.getElementById('modalImg') as HTMLImageElement;
        let src = img.src.substring(img.src.lastIndexOf('/') + 1);
        let newSrc = '';
        for (let i = 0; i < this.images.length; i++) {
            let key = i;
            if (this.images[i] === src) {
                if (i === 0) {
                    key = this.images.length;
                }
                newSrc = this.images[key - 1];
            }
        }
        img.src = 'assets/gallery/' + newSrc;
    }

    imageRight = function () {
        let img: HTMLImageElement = document.getElementById('modalImg') as HTMLImageElement;
        let src = img.src.substring(img.src.lastIndexOf('/') + 1);
        let newSrc = '';
        for (let i = 0; i < this.images.length; i++) {
            let key = i;
            if (this.images[i] === src) {
                if (i === this.images.length - 1) {
                    key = -1;
                }
                newSrc = this.images[key + 1];
            }
        }
        img.src = 'assets/gallery/' + newSrc;
    }
}
