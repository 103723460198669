import {AfterViewInit, Component, OnInit} from '@angular/core';
import {VideoService} from "../video.service";

@Component({
    selector: 'videos',
    templateUrl: './videos.component.html',
    styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements AfterViewInit {

    active: string = "";
    activeIndex: number;
    thumbnailImages: string[] = [];
    showCarousel: boolean = true;
    videos: string[] = [];
    interval = null;

    constructor(private videoService: VideoService) {
        this.videos = videoService.getVideos();
        this.activeIndex = 0;
        this.active = this.videos[this.activeIndex];
        this.thumbnailImages = this.setThumbnailImages(this.activeIndex);
    }

    async ngAfterViewInit(): Promise<void> {
        let img = document.querySelector('img');
        if (img.complete) {
            this.loaded();
        } else {
            img.addEventListener('load', this.loaded);
        }

        // await this.beforeVideoLoad();
        // this.loadCurrentVideo(this.active);
    }

    setThumbnailImages(index): string[] {
        return [this.getVideo(index - 2), this.getVideo(index - 1), this.getVideo(index), this.getVideo(index + 1), this.getVideo(index + 2)];
    }

    getVideo(index): string {
        if (index < 0)
            return this.videos[this.videos.length + index];
        else if (index > this.videos.length - 1)
            return this.videos[index - this.videos.length];
        else
            return this.videos[index];
    }

    async beforeVideoLoad(): Promise<boolean> {
        let video: HTMLVideoElement = document.getElementById('singleVideo' + this.active) as HTMLVideoElement;
        let source: HTMLSourceElement = document.getElementById('video_source') as HTMLSourceElement;
        if (video && source) {
            video.removeChild(source);
            return true;
        }
    }

    loadCurrentVideo(id): void {
        console.log('singleVideo' + id)
        let modal = document.getElementById('carousel' + id);
        let video: HTMLVideoElement = document.getElementById('singleVideo' + id) as HTMLVideoElement;
        let source: HTMLSourceElement = document.createElement('source') as HTMLSourceElement;
        console.log(video)
        if (video) {
            source.setAttribute('src', "assets/videos/" + id);
            source.setAttribute('id', 'video_source');
            video.appendChild(source);
            video.volume = 1;
        } else {
            // this.loadCurrentVideo(id)
        }
    }

    setIndex(index): number {
        if (index < 0)
            return this.videos.length + index;
        else if (index > this.videos.length - 1)
            return index - this.videos.length;
        else
            return index;
    }

    async setActiveVid(vid): Promise<void> {
        this.active = vid;
        this.activeIndex = this.videos.indexOf(vid);
        this.thumbnailImages = this.setThumbnailImages(this.activeIndex);
        // await this.beforeVideoLoad();
        // this.loadCurrentVideo(this.active);
    }

    async slide(change): Promise<void> {
        this.activeIndex = this.setIndex(this.activeIndex += change);
        this.active = this.getVideo(this.activeIndex);
        this.thumbnailImages = this.setThumbnailImages(this.activeIndex);
        // await this.beforeVideoLoad();
        // this.loadCurrentVideo(this.active);
    }

    loaded(): void {
        let picHeight = document.getElementById('picture').offsetHeight - 10 + "px";
        let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        let footerHeight = document.getElementById('footer').offsetHeight;
        let relative: HTMLElement = document.getElementById('relative');
        relative.style.height = vh - footerHeight - 52 + 'px';

        let container: HTMLElement = document.getElementById('container');
        container.style.top = picHeight;
    }

    /**
     * Show an image big
     * @param id ID of the html vid tag
     */
    async openImage(id): Promise<void> {
        await this.beforeVideoLoad();
        let modal = document.getElementById('modal');
        let video: HTMLVideoElement = document.getElementById('singleVideo') as HTMLVideoElement;
        let source: HTMLSourceElement = document.createElement('source') as HTMLSourceElement;
        source.setAttribute('src', "assets/videos/" + id);
        source.setAttribute('id', 'video_source');
        video.appendChild(source);
        modal.style.display = "block";
        video.volume = 0;
        video.play();

        let increaseBy = 0.001
        let currentVolume = 0;

        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }

        let interval = setInterval(function () {
            currentVolume = currentVolume + increaseBy;
            if (currentVolume >= 1) {
                clearInterval(interval);
            }
            video.volume = currentVolume;
        }, 4);

        this.interval = interval;
    }

    // Close the big image
    closeImage(): void {
        let modal = document.getElementById('modal');
        let video: HTMLVideoElement = document.getElementById('singleVideo') as HTMLVideoElement;
        let source: HTMLSourceElement = document.getElementById('video_source') as HTMLSourceElement;
        video.removeChild(source);
        modal.style.display = "none";
        clearInterval(this.interval);
        this.interval = null;
    }
}
