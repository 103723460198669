import {Component, AfterViewInit} from '@angular/core';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.sass']
})
export class HomeComponent implements AfterViewInit {

    constructor() {
    }

    ngAfterViewInit(): void {
        let img = document.querySelector('img');
        if (img.complete) {
            this.loaded();
        } else {
            img.addEventListener('load', this.loaded);
        }
    }

    loaded() {
        let picHeight = document.getElementById('picture').offsetHeight - 10 + "px";
        let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
        let footerHeight = document.getElementById('footer').offsetHeight;
        let relative: HTMLElement = document.getElementById('relative')
        relative.style.height = vh - footerHeight - 52 + 'px';

        let row = document.getElementById('firstrow');
        let rowheight = document.getElementById('textbox').offsetHeight;
        row.style.height = rowheight + 'px';

        let container: HTMLElement = document.getElementById('container');
        container.style.top = picHeight;
    }
}
