import {AfterViewInit, Component} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ApiService} from "../api.service";
@Component({
    selector: 'kontakt',
    templateUrl: './kontakt.component.html',
    styleUrls: ['./kontakt.component.scss']
})
export class KontaktComponent implements AfterViewInit {

    showError: boolean = false;
    apiService: ApiService = null;
    messageSuccess: boolean = false;
    messageError: boolean = false;

    constructor(private api: ApiService) {
        this.apiService = api;
    }

    ngAfterViewInit(): void {
        let img = document.querySelector('img');
        if (img.complete) {
            this.loaded();
        } else {
            img.addEventListener('load', this.loaded);
        }
    }

    loaded(): void {
        let picHeight = document.getElementById('picture').offsetHeight - 10 + "px";
        let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
        let footerHeight = document.getElementById('footer').offsetHeight;
        let relative: HTMLElement = document.getElementById('relative')
        relative.style.height = vh - footerHeight - 52 + 'px';

        let container: HTMLElement = document.getElementById('container');
        container.style.top = picHeight;
    }

    async sendEmail(f: NgForm): Promise<void> {
        if (f.valid) {
            this.showError = false;
            let info = {
                firstname: f.value.firstname,
                lastname: f.value.lastname,
                subject: f.value.subject,
                email: f.value.email,
                text: f.value.message
            };
            (await this.api.sendEmail(info))
                .subscribe(data => {
                    if (data.success) {
                        this.messageError = false;
                        this.messageSuccess = true;
                    } else {
                        this.messageError = true;
                        this.messageSuccess = false;
                    }
                })
        } else {
            this.showError = true;
        }
    }
}
