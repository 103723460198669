import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {UeberUnsComponent} from './ueber-uns/ueber-uns.component';
import {KontaktComponent} from './kontakt/kontakt.component';
import {ImpressumComponent} from './impressum/impressum.component';
import {LiederlisteComponent} from './liederliste/liederliste.component';
import {GalerieComponent} from './galerie/galerie.component';
import {VideosComponent} from './videos/videos.component';
import {TermineComponent} from './termine/termine.component';
import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';
import {FormsModule} from "@angular/forms";
import {ApiService} from "./api.service";

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        UeberUnsComponent,
        KontaktComponent,
        ImpressumComponent,
        LiederlisteComponent,
        GalerieComponent,
        VideosComponent,
        TermineComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        FormsModule,
        HttpClientModule
    ],
    providers: [ApiService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
