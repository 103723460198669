import {AfterViewInit, Component} from '@angular/core';
import {ImageService} from "../image.service";

@Component({
    selector: 'liederliste',
    templateUrl: './liederliste.component.html',
    styleUrls: ['./liederliste.component.scss']
})
export class LiederlisteComponent implements AfterViewInit {

    images: string[] = [];

    constructor(private imageService: ImageService) {
        this.images = imageService.getImages();
    }

    ngAfterViewInit(): void {
        let img = document.querySelector('img');
        if (img.complete) {
            this.loaded();
        } else {
            img.addEventListener('load', this.loaded);
        }

        let transition_time = 2000;
        let waiting_time = 3000;
        let images = $('div#picture_small_div img');
        let n = images.length;
        let current = this.random(n);
        images.hide();
        images.eq(current).show();

        let that = this;
        let interval_id = setInterval(function () {
            images.eq(current).fadeOut(transition_time, function () {
                current = that.random(n);
                images.eq(current).fadeIn(transition_time);
            });
        }, 2 * transition_time + waiting_time);
    }

    loaded() {
        let picHeight = document.getElementById('picture').offsetHeight - 10 + "px";
        let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
        let footerHeight = document.getElementById('footer').offsetHeight;
        let relative: HTMLElement = document.getElementById('relative')
        relative.style.height = vh - footerHeight - 52 + 'px';

        let container: HTMLElement = document.getElementById('container');
        container.style.top = picHeight;
    }

    random(n) {
        return Math.floor(Math.random() * n);
    }

}
